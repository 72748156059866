<!-- 个人积分  -->
<template>
  <div class="">
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :showToolBar="false"
      :searchForm="searchForm"
      :dataSourceApi="dataSourceApis"
      @action-column-click="actionColumnClick"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <template slot="phone" slot-scope="{ record }">{{
        record.phone ? record.phone : "--"
      }}</template>
    </ax-table>
    <!-- 详情弹窗 -->
    <detaila ref="detaila" @refresh="refresh"></detaila>
  </div>
</template>

<script>
import api from "./api";
import detaila from "./components/details.vue";
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
const searchForm = [
  {
    label: "姓名",
    type: "input",
    model: "residentName",
    options: {
      placeholder: "请输入",
    },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "联系电话",
    type: "input",
    model: "phone",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { detaila },
  data() {
    //这里存放数据
    return {
      api,
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "排行",
            dataIndex: "rankNumber",
            ellipsis: false,
            width: 80,
          },
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: true,
            width: 80,
          },
          {
            title: "联系电话",
            dataIndex: "phone",
            ellipsis: false,
            width: 120,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 150,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: false,
            width: 120,
          },

          {
            title: "剩余可用",
            dataIndex: "remainingPoints",
            ellipsis: true,
            width: 80,
          },
          {
            title: "累计获得",
            dataIndex: "totalPointsAdded",
            ellipsis: true,
            width: 80,
          },
          {
            title: "累计消耗",
            dataIndex: "totalPointsDeducted",
            ellipsis: true,
            width: 80,
          },
        ],
        false,
        {
          // fixed: "right",
          width: 180,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "积分明细",
                  name: "detail",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),

      selectedRowKeys: [],
      dataSourceApis: api.listRank,
      dataSourceParams: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getgridTree() {
      try {
        const res = await api.gridTree(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          console.log(options, this.$refs.tableBox);
          this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
            "gridId",
            {
              options: {
                options,
                allowClear: true,
                placeholder: "上级网格",
                changeOnSelect: true,
              },
            }
          );
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
    refresh() {
      this.$refs.tableBox.getDataSource();
    },
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 详情
        case "detail":
          this.$refs.detaila.openModal(record);
          break;
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    this.$nextTick(() => {
      this.getgridTree();
    });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
</style>