<!-- 审核弹窗 -->
<template>
  <div class="">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      width="50%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <div class="cardBox">
        <div class="card">
          <div class="icon">
            <img src="./image/xm.png" alt="" />
          </div>
          <div class="num">
            <div>{{ info.residentName }}</div>
            <span>姓名</span>
          </div>
        </div>
        <div class="card">
          <div class="icon">
            <img src="./image/syky.png" alt="" />
          </div>
          <div class="num">
            <div>{{ info.remainingPoints }}</div>
            <span>剩余可用</span>
          </div>
        </div>
        <div class="card">
          <div class="icon">
            <img src="./image/ljhd.png" alt="" />
          </div>
          <div class="num">
            <div>{{ info.totalPointsAdded }}</div>
            <span>累计获得</span>
          </div>
        </div>
        <div class="card">
          <div class="icon">
            <img src="./image/ljxf.png" alt="" />
          </div>
          <div class="num">
            <div>{{ info.totalPointsDeducted }}</div>
            <span>累计消费</span>
          </div>
        </div>
      </div>
      <!-- 积分明细 -->
      <div class="titleBox">
        <div class="titleLine" style=""></div>
        <div>积分明细</div>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        bordered
        :row-key="(record, index) => record.id"
        :indentSize="50"
        size="middle"
        :data-source="data"
      >
      </a-table>
    </el-dialog>
  </div>
</template>
  
  <script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "../api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      api,
      visible: false,
      title: "个人积分明细",
      columns: [
        {
          title: "来源",
          dataIndex: "pointsRoleType",
          ellipsis: true,
          width: 80,
          align: "center",
        },
        {
          title: "积分类型",
          dataIndex: "type",
          ellipsis: true,
          width: 120,
          align: "center",
        },
        {
          title: "积分变动",
          dataIndex: "pointsString",
          ellipsis: true,
          width: 150,
          align: "center",
        },
        {
          title: "时间",
          dataIndex: "updateTime",
          ellipsis: true,
          width: 120,
          align: "center",
        },
      ],
      data: [],
      info: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.visible = true;
      this.info = record;
      this.getDatalist(record.id);
    },
    async getDatalist(id) {
      const res = await api.listMy(id);
      this.data = res.data.records;
    },
    closeModal() {
      this.visible = false;
      this.$emit("refresh");
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
  <style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}

.titleBox {
  display: flex;
  align-items: center;
  font-size: 0.08rem;
  font-weight: 700;
  color: #2d3138;
  margin-bottom: 10px;
  .titleLine {
    height: 0.09rem;
    width: 0.015rem;
    background-color: #2a5cff;
    margin-right: 5px;
  }
}
.cardBox {
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
  .card {
    width: 20%;
    height: 0.56rem;
    background-color: pink;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    border-radius: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .icon {
      width: 20%;
      height: 35%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .num {
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        font-size: 0.13rem;
        color: #000;
        font-weight: 700;
      }
      span {
        margin-top: 5px;
        color: #aeb4b9;
      }
    }
  }
}

</style>