import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 获取排行列表
  listRank: params => get('/scResidentPointsRank/list', params),
  // 获取个人列表
  listMy: id => get(`/scPersonalPoints/list?residentId=${id}`),
       // 网格树
  gridTree:args => get('/scGridManagement/getTreeList', args),
  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),
}
